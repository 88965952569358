// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-auf-bestellung-js": () => import("./../../../src/pages/de/auf-bestellung.js" /* webpackChunkName: "component---src-pages-de-auf-bestellung-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-kontakt-js": () => import("./../../../src/pages/de/kontakt.js" /* webpackChunkName: "component---src-pages-de-kontakt-js" */),
  "component---src-pages-de-unseres-geback-js": () => import("./../../../src/pages/de/unseres-geback.js" /* webpackChunkName: "component---src-pages-de-unseres-geback-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-custom-orders-js": () => import("./../../../src/pages/en/custom-orders.js" /* webpackChunkName: "component---src-pages-en-custom-orders-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-our-pastry-js": () => import("./../../../src/pages/en/our-pastry.js" /* webpackChunkName: "component---src-pages-en-our-pastry-js" */),
  "component---src-pages-hr-contact-js": () => import("./../../../src/pages/hr/contact.js" /* webpackChunkName: "component---src-pages-hr-contact-js" */),
  "component---src-pages-hr-custom-orders-js": () => import("./../../../src/pages/hr/custom-orders.js" /* webpackChunkName: "component---src-pages-hr-custom-orders-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-our-pastry-js": () => import("./../../../src/pages/hr/our-pastry.js" /* webpackChunkName: "component---src-pages-hr-our-pastry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-na-zakazku-js": () => import("./../../../src/pages/na-zakazku.js" /* webpackChunkName: "component---src-pages-na-zakazku-js" */),
  "component---src-pages-nase-pecivo-js": () => import("./../../../src/pages/nase-pecivo.js" /* webpackChunkName: "component---src-pages-nase-pecivo-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-nasze-ciasto-js": () => import("./../../../src/pages/pl/nasze-ciasto.js" /* webpackChunkName: "component---src-pages-pl-nasze-ciasto-js" */),
  "component---src-pages-pl-zamowienia-niestandardowe-js": () => import("./../../../src/pages/pl/zamowienia-niestandardowe.js" /* webpackChunkName: "component---src-pages-pl-zamowienia-niestandardowe-js" */)
}

